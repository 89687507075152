import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveNacelles,
  findNacellesByTitle,
} from "../Nacelle/actions/nacelles";
import { Link } from "react-router-dom";
import Searchbar from "../components/searchbar";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";

class NacellesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveNacelle = this.setActiveNacelle.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllNacelles = this.removeAllNacelles.bind(this);
    this.goBack=this.goBack.bind(this)

    this.state = {
      currentNacelle: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveNacelles();

  }

  goBack() {
    this.props.history.goBack();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentNacelle: null,
      currentIndex: -1,
    });
  }

  setActiveNacelle(nacelle, index) {
    this.setState({
      currentNacelle: nacelle,
      currentIndex: index,
    });
  }

  removeAllNacelles() {
    this.props
      .deleteAllNacelles()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findNacellesByTitle(this.state.searchNom);
  }

  render() {
    const { currentIndex } = this.state;
    const { nacelles } = this.props;


    return (
        <div className="list" >
          <div className="fond" style={{backgroundImage: `url(${back})`}}>

          <div className="hautpage">
            <div className="textgoback">
              <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
                <th><p> Revenir à la page précédente</p></th></tr>
            </div>

            <h2 className="maj"> TOUS NOS NACELLES PROFESSIONNELS À LOUER
            </h2>
          </div>
          <div className="searchbar">
            <Searchbar/>
          </div>
          </div>
          <div className="col-md-6">
            <div className="nomcategorie">
              <div className="bloc">
                <tr>
                  <td id="text">
                    <p> NOS NACELLES </p>
                  </td>
                  <td id="att">
                    <h3> Tous les engins sont à louer avec ou sans opérateur</h3>
                  </td>

                </tr>
              </div>
            </div>


            <div className="listerecherche">
              {nacelles &&
              nacelles.map((nacelle, index) => (

                  <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }

                      onClick={() => this.setActiveNacelle(nacelle)}
                      key={nacelle.id}

                  >
                    <Link
                        to={"/" + nacelle.Categorie + "/" + nacelle.id}
                        className="badge badge-warning"
                    >
                      <td>
                        <img className="img" src={nacelle.Img1} alt={nacelle.Img1}/>
                      </td>
                      <td>
                        <p> {nacelle.Nom}</p>
                      </td>
                      <td>
                      </td>
                      <td>
                        <div className="buttonvoir">
                          <button type="button">
                            <p>
                              > Voir le matériel
                            </p>
                          </button>
                        </div>

                      </td>



                    </Link>
                  </li>


              ))}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nacelles: state.nacelles,
  };
};

export default connect(mapStateToProps, {
  retrieveNacelles,
  findNacellesByTitle,
})(NacellesList);
