import React from 'react';
import photo from '../photo/logo_attirail.png';
import back from "../photo/fond_image.png";
import pdf_operateur from "../CG/ATTIRAIL - CDL AVEC OPERATEUR.pdf"
import pdf_sans_operateur from "../CG/ATTIRAIL - CDL SANS OPERATEUR.pdf"

const Header = () => {
    return (
        <div>
        <div className="condition" style={{backgroundImage: `url(${back})`}}>
            <ul>
                        <img src={photo} alt='logo' id="logo_attirail"/>

                    <p id="text"> Conditions générales de location </p>
                </ul>




        </div>
            <div className="textecondition">
                <p> Vous retrouverez ci-joint les conditions générales de location de materiel avec opérateur :
                    <br/>
                            <a href={pdf_operateur} target="_blank">
                                <p className="lien_pdf">

                                    CDG_avec_opérateur.pdf

                                </p>
                            </a>

                </p>
            <br/>
                <br/>
                <p> Vous retrouverez ci-joint les conditions générales de location de materiel sans opérateur :
                    <br/>

                        <a href={pdf_sans_operateur} target="_blank">
                            <p className="lien_pdf">

                                CDG_sans_opérateur.pdf

                            </p>
                        </a>


                </p>
            </div>

        </div>
    );
};

export default Header;
