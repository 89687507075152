import {
CREATE_DUMPER,
RETRIEVE_DUMPERS,
UPDATE_DUMPER,
DELETE_DUMPER,
DELETE_ALL_DUMPERS
} from "./types";

import DumpersDataService from "../services/dumper.service";

export const createDumpers = (Nom, Categorie, Charge_max, Volume_max, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await DumpersDataService.create({ Nom, Categorie, Charge_max, Volume_max, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_DUMPER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await DumpersDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveDumpers = () => async (dispatch) => {

  try {
    const res = await DumpersDataService.getAll();

    dispatch({
      type: RETRIEVE_DUMPERS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateDumper = (id, data) => async (dispatch) => {
  try {
    const res = await DumpersDataService.update(id, data);

    dispatch({
      type: UPDATE_DUMPER,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteDumper = (id) => async (dispatch) => {
  try {
    await DumpersDataService.delete(id);

    dispatch({
      type: DELETE_DUMPER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllDumpers = () => async (dispatch) => {
  try {
    const res = await DumpersDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_DUMPERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findDumpersByTitle = (nom) => async (dispatch) => {
  try {
    const res = await DumpersDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_DUMPERS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};