import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveChenillards,
  findChenillardsByTitle,
} from "../Chenillard/actions/chenillards";
import { Link } from "react-router-dom";
import Searchbar from "../components/searchbar";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";

class ChenillardsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveChenillard = this.setActiveChenillard.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.goBack=this.goBack.bind(this);

    this.state = {
      currentChenillard: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveChenillards();


  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentChenillard: null,
      currentIndex: -1,
    });
  }

  setActiveChenillard(chenillard, index) {
    this.setState({
      currentChenillard: chenillard,
      currentIndex: index,
    });
  }
  goBack() {
    this.props.history.goBack();

  }


  findByNom() {
    this.refreshData();

    this.props.findChenillardsByTitle(this.state.searchNom);
  }

  render() {
    const {  currentIndex } = this.state;
    const { chenillards } = this.props;


    return (
        <div className="list" >
          <div className="fond" style={{backgroundImage: `url(${back})`}}>

          <div className="hautpage">
            <div className="textgoback">
              <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
                <th><p> Revenir à la page précédente</p></th></tr>
            </div>

            <h2 className="maj"> TOUS NOS CHENILLARDS PROFESSIONNELS À LOUER
            </h2>

          </div>
          <div className="searchbar">
            <Searchbar/>
          </div>
          </div>
          <div className="col-md-6">
            <div className="nomcategorie">
              <div className="bloc">
                <tr>
                  <td id="text">
                    <p> NOS CHENILLARDS </p>
                  </td>
                  <td id="att">
                    <h3> Tous les engins sont à louer avec ou sans opérateur</h3>
                  </td>

                </tr>
              </div>
            </div>


            <div className="listerecherche">
              {chenillards &&
              chenillards.map((chenillard, index) => (

                  <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }

                      onClick={() => this.setActiveChenillard(chenillard)}
                      key={chenillard.id}

                  >
                    <Link
                        to={"/" + chenillard.Categorie + "/" + chenillard.id}
                        className="badge badge-warning"
                    >
                      <td>
                        <img className="img" src={chenillard.Img1} alt={chenillard.Img1}/>
                      </td>
                      <td>
                        <p> {chenillard.Nom}</p>
                      </td>
                      <td>
                      </td>
                      <td>
                        <div className="buttonvoir">
                          <button type="button">
                            <p>
                              > Voir le matériel
                            </p>
                          </button>
                        </div>

                      </td>



                    </Link>
                  </li>


              ))}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chenillards: state.chenillards,
  };
};

export default connect(mapStateToProps, {
  retrieveChenillards,
  findChenillardsByTitle,

})(ChenillardsList);
