import {
  CREATE_REMORQUE,
  RETRIEVE_REMORQUES,
  UPDATE_REMORQUE,
  DELETE_REMORQUE,
  DELETE_ALL_REMORQUES
} from "../actions/types";

const initialState = [];

function remorqueReducer (remorques = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REMORQUE:
      return [...remorques, payload];

    case RETRIEVE_REMORQUES:
      return payload;

    case UPDATE_REMORQUE:
      return remorques.map((remorque) => {
        if (remorque.id === payload.id) {
          return {
            ...remorque,
            ...payload,
          };
        } else {
          return remorque;
        }
      });

    case DELETE_REMORQUE:
      return remorques.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_REMORQUES:
      return [];

    default:
      return remorques;
  }
}

export default remorqueReducer;