import http from "../../http-common";

class AutresDataService {



  getAll() {
    return http.get("/autres");
  }

  get(id) {
    return http.get(`/autres/${id}`);
  }

  findByTitle(Nom) {
    return http.get(`/autres?nom=${Nom}`);
  }
}

export default new AutresDataService();