import {
  CREATE_DUMPER,
  RETRIEVE_DUMPERS,
  UPDATE_DUMPER,
  DELETE_DUMPER,
  DELETE_ALL_DUMPERS
} from "../actions/types";

const initialState = [];

function dumperReducer(dumpers = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DUMPER:
      return [...dumpers, payload];

    case RETRIEVE_DUMPERS:
      return payload;

    case UPDATE_DUMPER:
      return dumpers.map((dumper) => {
        if (dumper.id === payload.id) {
          return {
            ...dumper,
            ...payload,
          };
        } else {
          return dumper;
        }
      });

    case DELETE_DUMPER:
      return dumpers.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_DUMPERS:
      return [];

    default:
      return dumpers;
  }
}

export default dumperReducer;