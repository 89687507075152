import {
CREATE_REMORQUE,
RETRIEVE_REMORQUES,
UPDATE_REMORQUE,
DELETE_REMORQUE,
DELETE_ALL_REMORQUES
} from "./types";

import RemorquesDataService from "../services/remorque.service";

export const createRemorques = (Nom, Categorie, Charge_max, Volume_max, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await RemorquesDataService.create({ Nom, Categorie, Charge_max, Volume_max, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_REMORQUE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await RemorquesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveRemorques = () => async (dispatch) => {

  try {
    const res = await RemorquesDataService.getAll();

    dispatch({
      type: RETRIEVE_REMORQUES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateRemorque = (id, data) => async (dispatch) => {
  try {
    const res = await RemorquesDataService.update(id, data);

    dispatch({
      type: UPDATE_REMORQUE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteRemorque = (id) => async (dispatch) => {
  try {
    await RemorquesDataService.delete(id);

    dispatch({
      type: DELETE_REMORQUE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllRemorques = () => async (dispatch) => {
  try {
    const res = await RemorquesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_REMORQUES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findRemorquesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await RemorquesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_REMORQUES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};