import {
  CREATE_VENTE,
  RETRIEVE_VENTES,

} from "../actions/types";

const initialState = [];

function venteReducer(ventes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VENTE:
      return [...ventes, payload];

    case RETRIEVE_VENTES:
      return payload;


    default:
      return ventes;
  }
}

export default venteReducer;