import React,{useState, useEffect} from "react";
import {Link} from "react-router-dom";
import photo from '../photo/logo_attirail.png';
import Button from "./Button";


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    useEffect(() =>{
        showButton();
    },[])

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>

                        <li> <img src={photo} alt='logo' class="fa-typo3"/>  </li>
                        <tr className="titre">
                        <li>Attirail</li>
                            <li id="sous-titre">Location d'engins ferroviaires</li>
                        </tr>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/location' className='nav-active' onClick={closeMobileMenu}>
                                Location
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/ventes'
                                className='nav-active'
                                onClick={closeMobileMenu}
                            >
                                Vente
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                to='/recrutement'
                                className='nav-active'
                                onClick={closeMobileMenu}
                            >
                                Recrutement
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/contact'
                                className='nav-active-mobile'
                                onClick={closeMobileMenu}
                            >
                                Contact
                            </Link>
                        </li>



                    </ul>
                    <li className='nav-item'>
                    {button && <Button buttonStyle='btn--outline' className='nav-active' onClick={closeMobileMenu}>
                        Contact
                    </Button>}
                    </li>



                </div>
            </nav>
        </>
    );
}

export default Navbar;