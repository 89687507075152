import {
RETRIEVE_CATEGORIES,
} from "./types";

import CategoriesDataService from "../services/categorie.service";


export const retrieveCategories = () => async (dispatch) => {

  try {
    const res = await CategoriesDataService.getAll();

    dispatch({
      type: RETRIEVE_CATEGORIES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};


export const findCategoriesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await CategoriesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_CATEGORIES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};