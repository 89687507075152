import React from 'react';
import axios from 'axios';
import UploadService from ".././contact/contact.service";
import back from "../photo/fond_image.png";
import photo1 from "../photo/photo accueil/IMG-20210614-WA0016.jpg";
import AliceCarousel from "react-alice-carousel";

import photoc1 from "../photo/Photo_contact/photo rail 3.jpg"
import photoc2 from "../photo/Photo_contact/IMG-20210614-WA0013.jpg"
import photoc3 from "../photo/Photo_contact/photo rail.jpg"



class Recrutement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            name: '',
            email: '',
            subject: '',
            message: '',
            telephone:'',
            fileName: '',
            selectedFile:undefined,
        }
    }


    upload = () => {
        console.log(this.state);


        if (this.state.selectedFile) {
                    let currentFile = this.state.selectedFile[0];
                    let fileName= currentFile.name;
                    this.setState({
                        progress: 0,
                        currentFile: currentFile,
                    });



                    UploadService.upload(currentFile, (event) => {
                        this.setState({
                            progress: Math.round((100 * event.loaded) / event.total),
                        });
                    }).then((response) => {
                        this.setState({

                                fileName:fileName,

                            }
                        )
                        console.log(this.state);
                        return UploadService.getFiles();
                    })
                        .then(() => {
            axios({
                method: "POST",
                url:"https://api.attirail.fr/message",
                data: this.state,
            }).then((response)=>{
                if (response.data.status === 'success'){
                    alert("Message envoyé !.");
                    this.resetForm()
                }else if(response.data.status === 'fail'){
                    alert("Problème lors de l'envoi du message, veuillez vous assurez d'avoir rempli tous les champs. Document en pdf.")
                }
            })})
                        .catch(() => {
                            this.setState({
                                progress: 0,
                                selectedFile: undefined,
                            });
                        });



                    this.setState({
                        selectedFile: undefined,
                    });
                }
            }

    selectFile = (event) => {
        this.setState({
                selectedFile: event.target.files,
            }
        );
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onTelephoneChange(event) {
        this.setState({telephone: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onSubjectChange(event) {
        this.setState({subject: event.target.value})
    }

    onMsgChange(event) {
        this.setState({message: event.target.value})
    }
    submitEmail(e){
        e.preventDefault();
        axios({
            method: "POST",
            url:"https://api.attirail.fr/messagecontact",
            data:  this.state
        }).then((response)=>{
            if (response.data.status === 'success'){
                alert("Message Sent.");
                this.resetForm()
            }else if(response.data.status === 'fail'){
                alert("Message failed to send.")
            }
        })
    }


    resetForm(){
        this.setState({name: '', email: '',subject:'', message: '',fileName:'',selectedFile:undefined,telephone:''})
    }


    render() {
        return (
            <div className="nousrejoindre">
                <div className="Hautpage" style={{backgroundImage: `url(${back})`}}>

                        <tr>
                            <td className="Titre">

                                <br/>

                                <div className="text">
                                    <h10> Attirail recrute et souhaite étendre son réseau de professionels</h10>

                                </div>
                            </td>

                            <td>
                                <div className="photo1">
                                <img src={photo1} alt="photo1"/>
                                </div>
                            </td>
                        </tr>

                </div>



                <div className="recrutement">
                    <tr>
                        <td>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                    <div className="form-group">
                                        <div className="liste">
                                            <div className="form-group">
                                                <label> Nom :</label>

                                                <input placeholder = "Nom"  id="name" type="text"
                                                       className="form-control" required value={this.state.name}
                                                       onChange={this.onNameChange.bind(this)}/>
                                            </div>
                                            <div className="form-group">
                                                <label> Email :</label>
                                                <input placeholder = "Email"  id="email" type="email"
                                                       className="form-control" aria-describedby="emailHelp"
                                                       required value={this.state.email} onChange=
                                                           {this.onEmailChange.bind(this)}/>
                                            </div>

                                            <div className="form-group">
                                                <label> Objet :</label>

                                        <input placeholder = "Objet"  id="subject" type="text"
                                               className="form-control" required value={this.state.subject}
                                               onChange={this.onSubjectChange.bind(this)}/>
                                            </div>

                                    <div className="form-group">
                                        <label> Téléphone :</label>

                                        <input placeholder = "Telephone"  id="telephone" type="text"
                                               className="form-control" required value={this.state.telephone}
                                               onChange={this.onTelephoneChange.bind(this)}/>
                                    </div>
                                    <div className="form-group">
                                        <label> Message :</label>

                                        <textarea placeholder = "Message"  id="message"
                                              className="form-control" rows="1"
                                              required value={this.state.message}
                                              onChange= {this.onMsgChange.bind(this)}/>
                                    </div>

                                    <label className="btn btn-default>"> Importer un CV :</label>
                                        <input type="file"  className="boutton" onChange={this.selectFile}/>
                                            <div className="envoyer">
                                            <button
                                                className="button"
                                                onClick={this.upload}
                                            >
                                                Envoyer
                                            </button>
                                            </div>
                                        </div>

                                    </div>


                            </div>
                        </div>

                    </div>
                        </td>


                        <td>
                                <div className="Carousel_recrutement">
                                    <AliceCarousel
                                        animationType={"fadeout"}
                                        autoPlay={true}
                                        autoPlayInterval={6000}
                                        infinite={true} >
                                        <img src={photoc1} className="sliderimg" alt="img1"/>
                                        <img src={photoc2} className="sliderimg" alt="img2"/>
                                        <img src={photoc3} className="sliderimg" alt="img3"/>
                                    </AliceCarousel>
                                </div>
                        </td>

                    </tr>


                </div>
            </div>
        );
    }

}

export default Recrutement;
