import {
  RETRIEVE_CHENILLARDS,

} from "../actions/types";

const initialState = [];

function chenillardReducer (chenillards = initialState, action) {
  const { type, payload } = action;

  switch (type) {


    case RETRIEVE_CHENILLARDS:
      return payload;



    default:
      return chenillards;
  }
}

export default chenillardReducer;