import React, {useState, useEffect} from 'react';
import {FaArrowCircleLeft,FaArrowCircleRight} from 'react-icons/fa';
import '../style/pages/_home.scss';
import '../style/pages/_categories-list.component.scss';

let positionscroll=0;

export const ScrollArrow = () =>{



    const [showScroll, setShowScroll] = useState(false)

    useEffect(()=>{
        window.addEventListener('scroll', checkScrollTop)
        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop)
        }
    })

    const checkScrollTop = () => {

        setShowScroll(true)
    }

    const scrollRight = () =>{


        var scroll = document.getElementById('list-group');
        positionscroll = scroll.scrollLeft + 270;

        scroll.scrollTo({left:positionscroll,behavior:'smooth'});

        if (positionscroll>= 1081) {
            positionscroll=1080
        }
        console.log(positionscroll);



    };

    const scrollLeft = () =>{


        var scroll = document.getElementById('list-group');
        positionscroll = scroll.scrollLeft - 270;

        scroll.scrollTo({left:positionscroll,behavior:'smooth'});

        if (positionscroll<= 0) {
            positionscroll=0;
        }

        console.log(positionscroll);



    };




    return (
        <ul className="arrows">

            <li>

                <div id="gauche">

            <FaArrowCircleLeft className="scrollLeft" onClick={scrollLeft} style={{height: 40, 'flex' : 'none'}}/>
                    </div>
                <div id="milieu"/>
                <div id="droite">
                    <FaArrowCircleRight className="scrollRight" onClick={scrollRight} style={{height: 40,  'flex' : 'none'}}/>
                </div>
            </li>
        </ul>
    );
}


