import {

RETRIEVE_AUTRES,

} from "./types";

import AutresDataService from "../services/autre.service";


export const retrieveAutres = () => async (dispatch) => {

  try {
    const res = await AutresDataService.getAll();

    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};


export const findAutresByTitle = (nom) => async (dispatch) => {
  try {
    const res = await AutresDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};