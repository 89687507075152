import {
RETRIEVE_NACELLES,

} from "./types";

import NacellesDataService from "../services/nacelle.service";
export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await NacellesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveNacelles = () => async (dispatch) => {

  try {
    const res = await NacellesDataService.getAll();

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};


export const findNacellesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await NacellesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
