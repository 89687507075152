import {
  RETRIEVE_ENGINS,
} from "../actions/types";

const initialState = [];

function enginReducer(engins = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_ENGINS:
      return payload;

    default:
      return engins;
  }
}

export default enginReducer;