import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveEngins,
  findEnginsByTitle,
} from "../Engin/actions/engin";
import {Link} from "react-router-dom";
import Searchbar from "../components/searchbar";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";

class EnginsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveEngin = this.setActiveEngin.bind(this);
    this.goBack=this.goBack.bind(this);
    this.findByNom = this.findByNom.bind(this);

    this.state = {
      currentEngin: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveEngins();

  }

  goBack() {
    this.props.history.goBack();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentEngin: null,
      currentIndex: -1,
    });
  }

  setActiveEngin(engin, index) {
    this.setState({
      currentEngin: engin,
      currentIndex: index,
    });
  }


  findByNom() {
    this.refreshData();

    this.props.findEnginsByTitle(this.state.searchNom);
  }

  render() {
    const {currentIndex} = this.state;
    const {engins} = this.props;


    return (
        <div className="list">
          <div className="fond" style={{backgroundImage: `url(${back})`}}>

          <div className="hautpage">
            <div className="textgoback">
              <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
                <th><p> Revenir à la page précédente</p></th></tr>
            </div>

            <h2 className="maj"> TOUS NOS ENGINS-TP PROFESSIONNELS À LOUER
            </h2>
          </div>
          <div className="searchbar">
            <Searchbar/>
          </div>
          </div>
          <div className="col-md-6">
            <div className="nomcategorie">
              <div className="bloc">
                <tr>
                  <td id="text">
                    <p> NOS ENGINS TP </p>
                  </td>
                  <td id="att">
                    <h3> Tous les engins sont à louer avec ou sans opérateur</h3>
                  </td>

                </tr>
              </div>
            </div>


            <div className="listerecherche">
              {engins &&
              engins.map((engin, index) => (

                  <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }

                      onClick={() => this.setActiveEngin(engin)}
                      key={engin.id}

                  >
                    <Link
                        to={"/" + engin.Categorie + "/" + engin.id}
                        className="badge badge-warning"
                    >
                      <td>
                        <img className="img" src={engin.Img1} alt={engin.Img1}/>
                      </td>
                      <td>
                        <p> {engin.Nom}</p>
                      </td>
                      <td>
                      </td>
                      <td>
                        <div className="buttonvoir">
                          <button type="button">
                            <p>
                              > Voir le matériel
                            </p>
                          </button>
                        </div>

                      </td>


                    </Link>
                  </li>


              ))}
            </div>
          </div>
        </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    engins: state.engins,
  };
};

export default connect(mapStateToProps, {retrieveEngins,findEnginsByTitle})(EnginsList)
