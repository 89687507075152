import {
  RETRIEVE_AUTRES,
} from "../actions/types";

const initialState = [];

function autreReducer(autres = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_AUTRES:
      return payload;

    default:
      return autres;
  }
}

export default autreReducer;
