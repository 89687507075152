import http from "../../http-common";

class ChenillardsDataService {

  upload(files, onUploadProgress) {
    let formData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('file', files[i]);
    }

    return http.post("/fichierchenillards/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/fichierchenillards/getListFiles");
  }

  getAll() {
    return http.get("/chenillards");
  }

  get(id) {
    return http.get(`/chenillards/${id}`);
  }

  create(data) {
    return http.post("/chenillards", data);

  }


  findByTitle(Nom) {
    return http.get(`/chenillards?nom=${Nom}`);
  }
}

export default new ChenillardsDataService();
