import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrievePelles,
  findPellesByTitle,
} from "../Pelle/actions/pelles";
import { Link } from "react-router-dom";
import Searchbar from "../components/searchbar";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";


class PellesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActivePelle = this.setActivePelle.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllPelles = this.removeAllPelles.bind(this);
    this.goBack=this.goBack.bind(this);

    this.state = {
      currentPelle: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrievePelles();

  }

  goBack() {
    this.props.history.goBack();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentPelle: null,
      currentIndex: -1,
    });
  }

  setActivePelle(pelle, index) {
    this.setState({
      currentPelle: pelle,
      currentIndex: index,
    });
  }

  removeAllPelles() {
    this.props
      .deleteAllPelles()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findPellesByTitle(this.state.searchNom);
  }

  render() {
    const { currentIndex } = this.state;
    const { pelles } = this.props;
    console.log({pelles});


    return (
      <div className="list">
        <div className="fond" style={{backgroundImage: `url(${back})`}}>

        <div className="hautpage" >
          <div className="textgoback">
            <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
              <th><p> Revenir à la page précédente</p></th></tr>
          </div>

          <h2 className="maj"> TOUS NOS PELLES PROFESSIONNELS À LOUER
          </h2>
        </div>
        <div className="searchbar">
          <Searchbar/>
        </div></div>
        <div className="col-md-6">
          <div className="nomcategorie">
            <div className="bloc">
              <tr>
                <td id="text">
                  <p> NOS PELLES </p>
                </td>
                <td id="att">
                  <h3> Tous les engins sont à louer avec ou sans opérateur</h3>
                </td>

              </tr>
            </div>
          </div>


          <div className="listerecherche">
            {pelles &&
            pelles.map((pelle, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActivePelle(pelle)}
                    key={pelle.id}

                >
                  <Link
                    to={"/" + pelle.Categorie + "/" + pelle.id}
                    className="badge badge-warning"
                >
                  <td>
                    <img className="img" src={pelle.Img1} alt={pelle.Img1}/>
                  </td>
                  <td>
                    <p> {pelle.Nom}</p>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div className="buttonvoir">
                      <button type="button">
                        <p>
                               > Voir le matériel
                        </p>
                      </button>
                    </div>

                  </td>



                </Link>
                </li>


            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pelles: state.pelles,
  };
};

export default connect(mapStateToProps, {
  retrievePelles,
  findPellesByTitle,
})(PellesList);
