import React, { Component } from "react";
import { connect } from "react-redux";
import ChenillardsDataService from "../Chenillard/services/chenillard.service";
import { PDFViewer } from 'react-view-pdf';


import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";

class Chenillard extends Component {
  constructor(props) {
    super(props);

    this.getChenillard = this.getChenillard.bind(this);
    this.goBack = this.goBack.bind(this);


    this.state = {
      currentChenillard: {
        id: null,
        Nom: "",
        Charge_max:"",
        Autonomie:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getChenillard(this.props.match.params.id);
  }

  goBack() {
    this.props.history.goBack();

  }
  getChenillard(id) {
    ChenillardsDataService.get(id)
      .then((response) => {
        this.setState({
          currentChenillard: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  render() {

    const {
      currentChenillard,
    } = this.state;
    const fichetechnique=currentChenillard.Fiche_technique;


    return (
        <div>
          <div className="pageproduit">

            <tr>
              <td className="goback">
                <div className="textgoback">
                  <a onClick={this.goBack}><p> Revenir à la page précédente</p></a>
                </div>
                <h1> {currentChenillard.Nom} </h1>
              </td>

            </tr>
            <tr> <h3> Agrée SNCF </h3></tr>

            <tr className="infoproduit">
              <td>
                <div className="Carouselproduit">
                  <AliceCarousel
                      autoPlay={true}
                      autoPlayInterval={4000}
                      infinite={true} >
                    <img src={currentChenillard.Img1} className="sliderimg" alt="img1"/>
                    <img src={currentChenillard.Img2} className="sliderimg" alt="img2"/>
                    <img src={currentChenillard.Img3} className="sliderimg" alt="img3"/>
                  </AliceCarousel></div>
              </td>

              <td className="carac">
                <h2>
                  Caractéristiques
                </h2>
                <div className="caracteristiques">
                  <li id="carac">

                    Charge maximal : {currentChenillard.Charge_max}

                  </li>
                  <li id="carac">
                    Autonomie : {currentChenillard.Autonomie}

                  </li>


                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <div className="fichetechnique">

                  {fichetechnique!=='NULL' && (
                      <a href={currentChenillard.Fiche_technique}>
                        <p className="fichetechnique" >

                          Télécharger la fiche technique

                        </p>
                      </a>)}
                  </div>
                </div>
              </td>

              <td id="tddevis">
                <div className="devis">
                  <Link to={"/contact"}>
                    <button> Demander un devis </button>
                  </Link></div>
              </td>


            </tr>
            {fichetechnique!=='NULL' && (
                <div className="pdfviewer">

                  <h4>Fiche technique</h4>

                  <PDFViewer url={currentChenillard.Fiche_technique} />
                </div>
            ) }




          </div>

        </div>
    );
  }
}

export default connect(null, {  })(Chenillard);
