import Searchbar from "../components/searchbar";

import React, {Component} from 'react';
import photo1 from '../photo/photo accueil/IMG-20210614-WA0005.jpg';
import photo2 from '../photo/photo accueil/IMG-20210614-WA0016.jpg';
import photo3 from '../photo/photo accueil/photo_rail_5.jpg'

import back from '../photo/fond_image.png'
import pres from '../photo/IMG_0007.jpg';
import pres2 from '../photo/photo accueil/IMG_2709.JPG'

import {Link} from "react-router-dom";
import {
    retrieveCategories,
    findCategoriesByTitle,

} from "../Categorie/actions/categories";
import {connect} from "react-redux";
import AliceCarousel from "react-alice-carousel";
import {ScrollArrow} from "../scroll/scroll";



class Home extends Component {

    constructor(props) {

        super(props);
        this.props = props;



        this.state = {
            currentCategorie: null,
            currentIndex: -1,
            searchNom: "",
            positionscroll:15,
        };
    }




    componentDidMount() {
        this.props.retrieveCategories();


    }




    setActiveCategorie(categorie, index) {
        this.setState({
            currentCategorie: categorie,
            currentIndex: index,
        });
    }


    render() {

        const {currentIndex} = this.state;
        const {categories} = this.props;





        return (
            <div>

                <div className="Hautpage" style={{backgroundImage: `url(${back})`}}>
                    <div className="tableau">

                        <tr>
                            <td className="tdtitre" >
                                <div className="Titre">
                                    <br/>


                                    <p>Location d'engins ferroviaires agrées SNCF <p id="operateur">
                                        avec ou sans opérateurs</p></p>


                                    <div className="Searchbar">
                                        <Searchbar/>
                                    </div>
                                </div>
                            </td>

                            <td className="carouselhome" >
                                <div className="photo1">
                                    <AliceCarousel
                                        animationType={"fadeout"}
                                        autoPlay={true}
                                        controlsStrategy={"responsive"}
                                        autoPlayInterval={6000}
                                        infinite={true}
                                        disableDotsControls={"false"}
                                    >
                                        <img src={photo1} className="sliderimg" alt="img1"/>
                                        <img src={photo2} className="sliderimg" alt="img2"/>
                                        <img src={photo3} className="sliderimg" alt="img3"/>
                                    </AliceCarousel>
                                </div>
                            </td>
                        </tr>

                    </div>
                </div>

                <div className="entre2">
                    <br/>
                    <h3> LE CHOIX PRODUIT </h3>

                    <p>Retrouvez tous les engins nécessaires pour vos travaux</p>

                </div>
                <div className="list-group" id="list-group">
                    <div>

                        <br/>
                        <br/>
                        <tr>


                            {categories &&
                            categories.map((categorie, index) => (

                                <td>
                                    <Link to={"/" + categorie.Nom + "s" + "/"}>

                                        <div className="container" id="container" style={{backgroundImage: `url(${categorie.Img1})`}}>

                                            <div className="nomcat">

                                                <h2
                                                    className={
                                                        "list-group-item " +
                                                        (index === currentIndex ? "active" : "")
                                                    }

                                                    onClick={() => this.setActiveCategorie(categorie)}
                                                    key={categorie.id}
                                                    style={{textAlign: 'center'}}
                                                >
                                                    {categorie.Nom}
                                                </h2>


                                            </div>
                                        </div>

                                    </Link>
                                </td>
                            ))}

                        </tr>
                        <div className="fleches" >
                        <ScrollArrow/>
                        </div>
                    </div>


                </div>






                <div className="presentation">

                    <tr>
                        <h1> Qui sommes-nous?</h1>
                    </tr>
                    <tr>
                        <td>
                            <img className="pres" src={pres} alt="photo qui sommes nous"/>
                            <h4 className="textpres">
                                Attirail est une société spécialisée dans la location de matériel ferroviaire.
                                Nous vous proposons une large gamme de matériel ferroviaire ainsi que notre expertise afin de mener à bien tous vos projets.
                                <br/>

                            </h4>
                        </td>

                        <td >
                            <div id='TD2'>
                                <h4 className="textpres2"> Basée en Ile de France, Attirail intervient sur l’ensemble du territoire National et Européen.
                                    Exprimez vos besoins ou contraintes, Attirail est là pour répondre à vos demandes de location ponctuelle ou de longue durée.</h4>
                                <p className="textpres2">Nous vous proposons des opérateurs qualifiés avec nos engins agrées SNCF.</p>
                                <div className="pres2">
                                    <AliceCarousel
                                        animationType={"fadeout"}
                                        autoPlay={true}
                                        controlsStrategy={"responsive"}
                                        autoPlayInterval={6000}
                                        disableDotsControls={"false"}
                                        infinite={true} >
                                        <img src={pres2} className="sliderimg" alt="img1"/>
                                        <img src={photo2} className="sliderimg" alt="img2"/>
                                        <img src={photo3} className="sliderimg" alt="img3"/>
                                    </AliceCarousel>
                                </div>
                            </div>
                        </td>
                    </tr>


                </div>




            </div>

        );


    }
}




const mapStateToProps = (state) => {
    return {
        categories: state.categories,
    };
};

export default connect(mapStateToProps, {retrieveCategories,findCategoriesByTitle})(Home)
