import React, { Component } from "react";
import { connect } from "react-redux";
import AutresDataService from "../Autre/services/autre.service";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";

class Autre extends Component {
  constructor(props) {
    super(props);
    this.getAutre = this.getAutre.bind(this);
    this.goBack=this.goBack.bind(this);

    this.state = {
      currentAutre: {
        id: null,
        Nom: "",
        Description:"",
        published:false
      },
      message: "",
      selectedFiles: undefined,
      progress: 0,
      fileInfos: [],
      fileName:"",
    };
  }

  componentDidMount() {
    this.getAutre(this.props.match.params.id);
  }

  goBack() {
    this.props.history.goBack();

  }

  getAutre(id) {
    AutresDataService.get(id)
      .then((response) => {
        this.setState({
          currentAutre: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  render() {

    const {
      currentAutre,
    } = this.state;


    return (
        <div>
          <div className="pageproduit">

            <tr>
              <td>
                <h1> {currentAutre.Nom} </h1>
              </td>
              <td className="goback">
                <div className="textgoback">
                  <a onClick={this.goBack}><p> Revenir à la page précédente</p></a>
                </div>
              </td>
            </tr>
            <tr> <h3> Agrée SNCF </h3></tr>

            <tr className="infoproduit">
              <td>
                <div className="Carouselproduit">
                  <AliceCarousel
                      autoPlay={true}
                      autoPlayInterval={4000}
                      infinite={true} >
                    <img src={currentAutre.Img1} className="sliderimg" alt="img1"/>

                  </AliceCarousel></div>
              </td>

              <td className="carac">
                <h2>
                  Caractéristiques
                </h2>
                <div className="caracteristiques">
<br/>
                <br/>
                  <li>


                    Descriptions :
                  </li>
                  <br/>
                  <li>
                    {currentAutre.Description}

                  </li>


                </div>
              </td>

              <td id="tddevis">
                <div className="devis">
                  <Link to={"/contact"}>
                    <button> Demander un devis </button>
                  </Link></div>
              </td>


            </tr>




          </div>

        </div>
    );
  }

}

export default connect(null)(Autre);
