import {
  RETRIEVE_CATEGORIES,
} from "../actions/types";

const initialState = [];

function categorieReducer(categories = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_CATEGORIES:
      return payload;


    default:
      return categories;
  }
}

export default categorieReducer;