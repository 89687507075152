import React from 'react';
import { BiMailSend } from "react-icons/bi";

const Header = () => {
    return (
        <nav className="header">
            <ul>
                <div className="BiMailSend">
                    <BiMailSend/>
                </div>
                <p> <a href="mailto:location@attirail.fr"> location@attirail.fr</a></p>
                <p> 01.69.40.40.29</p>
            </ul>
        </nav>
    );
};

export default Header;