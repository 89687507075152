import React, { Component } from "react";
import { connect } from "react-redux";
import loupe from "../photo/585e4ae1cb11b227491c3393.png"



import {
  retrieveProduits,findProduitsByTitle
} from "../Searchbar/actions/search"

import "../style/pages/_search-list.component.scss"
import { Link } from "react-router-dom";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";




class SearchList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.findByNom = this.findByNom.bind(this);
  this.goBack=this.goBack.bind(this);

    let searchNom = localStorage.getItem('state');
    JSON.parse(searchNom);
    searchNom= searchNom.replace(/['"]+/g, '');
    console.log(searchNom);
    this.state = {
     currentProduit:null,
      currentIndex: -1,
      searchNom: searchNom,
    };


  }


  componentDidMount() {
    this.findByNom();
  }

  goBack() {
    this.props.history.goBack();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
    localStorage.setItem('state', JSON.stringify(searchNom))

  }

  refreshData() {
    this.setState({
      currentProduit:null,
      currentIndex: -1,
    });
  }



  findByNom() {
    this.refreshData();
    this.props.findProduitsByTitle(this.state.searchNom);



  }

  render() {
    const { searchNom ,currentIndex } = this.state;

    const {produits} = this.props;
    console.log(produits);


    return (
      <div className="pagerecherchhe">
        <div className="fond" style={{backgroundImage: `url(${back})`}}>

          <div className="textgoback">
            <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
              <th><p> Revenir à la page précédente</p></th></tr>
          </div>

        <div className="hautpage">
          <h2>RECHERCHE </h2>

        </div>


        <div>
          <form className="hautpagerecherche">


            <input
              type="text"
              className="form-control"
              placeholder="Saisissez votre recherche"
              value={searchNom}
              onChange={this.onChangeSearchNom}
              id="input"
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
                id="button"

              >
                <img src={loupe} alt="loupe" id="loupe"/>
              </button>

            </div>
          </form>
        </div>
        </div>

        <div className="nosproduits">
          <div className="bloc">
          <tr>
            <td>
              <p> NOS PRODUITS </p>
            </td>

          </tr>
          </div>

        </div>

        <div className="listerecherche">

          <div>
            {produits[0] &&
            produits[0].map((produit, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }


                >
                  <Link
                      to={"/" + produit.Categorie + "/" + produit.id}
                      className="badge badge-warning"
                  >
                  <td>
                    <img className="img" src={produit.Img1} alt={produit.Img1}/>
                  </td>
                    <td>
                      <p> {produit.Nom}</p>
                    </td>
                    <td>
                    </td>
                    <td>
                      <div className="buttonvoir">
                        <button type="button">
                          <p>
                           > Voir le matériel
                          </p>
                        </button>
                      </div>

                    </td>



              </Link>
                </li>

            ))}
          </div>

          <div>
            {produits[1] &&
            produits[1].map((produit, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }


                >
                  <Link
                      to={"/" + produit.Categorie + "/" + produit.id}
                      className="badge badge-warning"
                  >
                    <td>
                      <img className="img" src={produit.Img1} alt={produit.Img1}/>
                    </td>
                    <td>
                      <p> {produit.Nom}</p>
                    </td>
                    <td>
                    </td>
                    <td>
                      <div className="buttonvoir">
                        <button type="button">
                          <p>
                           > Voir le matériel
                          </p>
                        </button>
                      </div>

                    </td>



                  </Link>
                </li>

            ))}
          </div>

          <div>
            {produits[2] &&
            produits[2].map((produit, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }


                >
                  <Link
                      to={"/" + produit.Categorie + "/" + produit.id}
                      className="badge badge-warning"
                  >
                    <td>
                      <img className="img" src={produit.Img1} alt={produit.Img1}/>
                    </td>
                    <td>
                      <p> {produit.Nom}</p>
                    </td>
                    <td>
                    </td>
                    <td>
                      <div className="buttonvoir">
                        <button type="button">
                          <p>
                           > Voir le matériel
                          </p>
                        </button>
                      </div>

                    </td>



                  </Link>
                </li>

            ))}
          </div>

          <div>
            {produits[3] &&
            produits[3].map((produit, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }


                >
                  <Link
                      to={"/" + produit.Categorie + "/" + produit.id}
                      className="badge badge-warning"
                  >
                    <td>
                      <img className="img" src={produit.Img1} alt={produit.Img1}/>
                    </td>
                    <td>
                      <p> {produit.Nom}</p>
                    </td>
                    <td>
                    </td>
                    <td>
                      <div className="buttonvoir">
                        <button type="button">
                          <p>
                           > Voir le matériel
                          </p>
                        </button>
                      </div>

                    </td>


                  </Link>
                </li>

            ))}
          </div>

          <div>
            {produits[4] &&
            produits[4].map((produit, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }


                >
                  <Link
                      to={"/" + produit.Categorie + "/" + produit.id}
                      className="badge badge-warning"
                  >
                    <td>
                      <img className="img" src={produit.Img1} alt={produit.Img1}/>
                    </td>
                    <td>
                      <p> {produit.Nom}</p>
                    </td>
                    <td>
                    </td>
                    <td>
                      <div className="buttonvoir">
                        <button type="button">
                          <p>
                            > Voir le matériel
                          </p>
                        </button>
                      </div>

                    </td>



                  </Link>
                </li>

            ))}
          </div>


        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    produits: state.produits,

  };
};

export default connect(mapStateToProps, {retrieveProduits
  ,findProduitsByTitle})(SearchList);
