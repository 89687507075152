import React, { Component } from "react";
import { connect } from "react-redux";
import EnginsDataService from "../Engin/services/engin.service";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";
import {PDFViewer} from "react-view-pdf";

class Engin extends Component {
  constructor(props) {
    super(props);
    this.getEngin = this.getEngin.bind(this);
    this.goBack=this.goBack.bind(this);

    this.state = {
      currentEngin: {
        id: null,
        Nom: "",
        Description:"",
        Fiche_technique: "",
      },
      message: "",
      selectedFiles: undefined,
      progress: 0,
      fileInfos: [],
      fileName:"",
    };
  }

  componentDidMount() {
    this.getEngin(this.props.match.params.id);
  }

  goBack() {
    this.props.history.goBack();

  }

  getEngin(id) {
    EnginsDataService.get(id)
      .then((response) => {
        this.setState({
          currentEngin: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  render() {

    const {
      currentEngin,
    } = this.state;
    const fichetechnique=currentEngin.Fiche_technique;



    return (
        <div>
          <div className="pageproduit">

            <tr>
              <td className="goback">
                <div className="textgoback">
                  <a onClick={this.goBack}><p> Revenir à la page précédente</p></a>
                </div>
                <h1> {currentEngin.Nom} </h1>
              </td>

            </tr>
            <tr> <h3> Agrée SNCF </h3></tr>

            <tr className="infoproduit">
              <td>
                <div className="Carouselproduit">
                  <AliceCarousel
                      autoPlay={true}
                      disableDotsControls={false}
                      autoPlayInterval={4000}
                      infinite={true} >
                    <img src={currentEngin.Img1} className="sliderimg" alt="img1"/>

                  </AliceCarousel></div>
              </td>

              <td className="carac">
                <h2>
                  Caractéristiques
                </h2>
                <div className="caracteristiques">
<br/>
                <br/>
                  <li>


                    Descriptions :
                  </li>
                  <br/>
                  <li>
                    {currentEngin.Description}

                  </li>

                  <div className="fichetechnique">

                    {fichetechnique!=='NULL' && (
                        <a href={currentEngin.Fiche_technique}>
                          <p className="fichetechnique" >

                            Télécharger la fiche technique

                          </p>
                        </a>)}
                  </div>
                </div>
              </td>

              <td id="tddevis">
                <div className="devis">
                  <Link to={"/contact"}>
                    <button> Demander un devis </button>
                  </Link></div>
              </td>


            </tr>
            {fichetechnique!=='NULL' && (
                <div className="pdfviewer">

                  <h4>Fiche technique</h4>

                  <PDFViewer url={currentEngin.Fiche_technique} />
                </div>
            ) }




          </div>

        </div>
    );
  }

}

export default connect(null)(Engin);
