import http from "../../http-common";

class SearchDataService {

  getAll() {
    return http.get("/searchbar");
  }


  findByTitle(Nom) {
    return http.get(`/searchbar?nom=${Nom}`);
  }
}

export default new SearchDataService();