import {
CREATE_PELLE,
RETRIEVE_PELLES,
UPDATE_PELLE,
DELETE_PELLE,
DELETE_ALL_PELLES
} from "./types";

import PellesDataService from "../services/pelle.service";

export const createPelles = (Nom, Poids, Categorie, Profondeur_de_fouille, Portee_laterale, Hauteur_de_travail, Longueur, Largeur, Hauteur, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await PellesDataService.create({ Nom ,Poids, Categorie, Profondeur_de_fouille, Portee_laterale, Hauteur_de_travail,Longueur, Largeur, Hauteur, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_PELLE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await PellesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrievePelles = () => async (dispatch) => {

  try {
    const res = await PellesDataService.getAll();

    dispatch({
      type: RETRIEVE_PELLES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updatePelle = (id, data) => async (dispatch) => {
  try {
    const res = await PellesDataService.update(id, data);

    dispatch({
      type: UPDATE_PELLE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deletePelle = (id) => async (dispatch) => {
  try {
    await PellesDataService.delete(id);

    dispatch({
      type: DELETE_PELLE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllPelles = () => async (dispatch) => {
  try {
    const res = await PellesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_PELLES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findPellesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await PellesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_PELLES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};