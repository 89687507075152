import {

RETRIEVE_ENGINS,

} from "./types";

import EnginsDataService from "../services/engin.service";


export const retrieveEngins = () => async (dispatch) => {

  try {
    const res = await EnginsDataService.getAll();

    dispatch({
      type: RETRIEVE_ENGINS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};


export const findEnginsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await EnginsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_ENGINS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};