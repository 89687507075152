import { combineReducers } from "redux";
import categories from "./categories";
import pelles from "../../Pelle/reducers/pelles";
import nacelles from "../../Nacelle/reducers/nacelle";
import dumpers from "../../Dumper/reducers/dumper";
import remorques from "../../Remorque/reducers/remorque";
import autres from "../../Autre/reducers/autres"
import produits from "../../Searchbar/reducers/search";
import chenillards from "../../Chenillard/reducers/chenillard";
import ventes from "../../Vente/reducers/ventes";
import engins from "../../Engin/reducers/engins"



export default combineReducers({
  pelles,
  categories,
  nacelles,
  dumpers,
  remorques,
  autres,
  chenillards,
  produits,
  ventes,
  engins


});
