import {
RETRIEVE_CHENILLARDS,

} from "./types";

import ChenillardsDataService from "../services/chenillard.service";


export const retrieveChenillards = () => async (dispatch) => {

  try {
    const res = await ChenillardsDataService.getAll();

    dispatch({
      type: RETRIEVE_CHENILLARDS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};


export const findChenillardsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_CHENILLARDS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
