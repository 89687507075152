import {

RETRIEVE_NACELLES,
RETRIEVE_PELLES,
RETRIEVE_REMORQUES,
RETRIEVE_DUMPERS,
RETRIEVE_AUTRES,

} from "./types";


import SearchDataService from "../services/search.service";

//produits
export const retrieveProduits = () => async (dispatch) => {

  try {
    const res = await SearchDataService.getAll();

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data,
    });

    dispatch({
      type: RETRIEVE_PELLES,
      payload: res.data,
    });
    dispatch({
      type: RETRIEVE_REMORQUES,
      payload: res.data,
    });

    dispatch({
      type: RETRIEVE_DUMPERS,
      payload: res.data,
    });
    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};

export const findProduitsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await SearchDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data
    });
    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data,
    });

    dispatch({
      type: RETRIEVE_PELLES,
      payload: res.data,
    });
    dispatch({
      type: RETRIEVE_REMORQUES,
      payload: res.data,
    });

    dispatch({
      type: RETRIEVE_DUMPERS,
      payload: res.data,
    });
    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};

