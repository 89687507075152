import http from "../../http-common";

class RemorquesDataService {

  upload(files, onUploadProgress) {
    let formData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('file', files[i]);
    }

    return http.post("/fichierremorques/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/fichierremorques/getListFiles");
  }

  getAll() {
    return http.get("/remorques");
  }

  get(id) {
    return http.get(`/remorques/${id}`);
  }

  create(data) {
    return http.post("/remorques", data);

  }

  update(id, data) {
    return http.put(`/remorques/${id}`, data);

  }

  delete(id) {
    return http.delete(`/remorques/${id}`);
  }

  deleteAll() {
    return http.delete(`/remorques`);
  }

  findByTitle(Nom) {
    return http.get(`/remorques?nom=${Nom}`);
  }
}

export default new RemorquesDataService();