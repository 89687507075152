import {
  RETRIEVE_NACELLES,
  RETRIEVE_PELLES,
  RETRIEVE_REMORQUES,
  RETRIEVE_DUMPERS,
  RETRIEVE_AUTRES,
} from "../actions/types";

const initialState = [];

function produitReducer(produits = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_NACELLES && RETRIEVE_DUMPERS && RETRIEVE_REMORQUES && RETRIEVE_PELLES && RETRIEVE_AUTRES:
      return payload;

    default:
      return produits;
  }
}





export default produitReducer ;