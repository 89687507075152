import {
  CREATE_PELLE,
  RETRIEVE_PELLES,
  UPDATE_PELLE,
  DELETE_PELLE,
  DELETE_ALL_PELLES
} from "../actions/types";

const initialState = [];

function pelleReducer(pelles = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PELLE:
      return [...pelles, payload];

    case RETRIEVE_PELLES:
      return payload;

    case UPDATE_PELLE:
      return pelles.map((pelle) => {
        if (pelle.id === payload.id) {
          return {
            ...pelle,
            ...payload,
          };
        } else {
          return pelle;
        }
      });

    case DELETE_PELLE:
      return pelles.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_PELLES:
      return [];

    default:
      return pelles;
  }
}

export default pelleReducer;