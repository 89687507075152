import http from "../../http-common";

class EnginsDataService {



  getAll() {
    return http.get("/engins");
  }

  get(id) {
    return http.get(`/engins/${id}`);
  }

  findByTitle(Nom) {
    return http.get(`/engins?nom=${Nom}`);
  }
}

export default new EnginsDataService();