import React, { Component } from "react";
import { connect } from "react-redux";
import {
    retrieveProduits,findProduitsByTitle
} from "../Searchbar/actions/search";
import { Link } from "react-router-dom";
import loupe from "../photo/585e4ae1cb11b227491c3393.png"


class Searchbar extends Component {
    constructor(props) {
        super(props);
        this.onChangeSearchNom = this.onChangeSearchNom.bind(this);

        this.state = {
            currentsearchNom:null,
            currentIndex: -1,
            searchNom: "",
        };
    }


    onChangeSearchNom(e) {
        const searchNom = e.target.value;

        this.setState({
            searchNom: searchNom,
        });
        localStorage.setItem('state', JSON.stringify(searchNom))
        console.log(searchNom);

    }




    render() {
        const { searchNom } = this.state;

        return (
            <div >
                    <form className="recherche" >


                        <input
                            type="text"
                            className="form-control"
                            placeholder="De quoi avez-vous besoin?"
                            value={searchNom}
                            onChange={this.onChangeSearchNom}
                            id="input"
                        />

                        <Link to={'/searchbar/'}>
                            <button
                                className="btn btn-outline-secondary"
                                type="submit"
                                id="button"

                            >
                                <img src={loupe} alt="loupe" id="loupe"/>

                            </button></Link>
                    </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        research: state.research,

    };
};

export default connect(mapStateToProps, {
    retrieveProduits,findProduitsByTitle})(Searchbar);
