import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";



import CategoriesList from "./pages/categories-list.component";



import Pelle from "./pages/pelle.component";
import PellesList from "./pages/pelles-list.component";


import Nacelle from "./pages/nacelle.component";
import NacellesList from "./pages/nacelles-list.component";

import Dumper from "./pages/dumper.component";
import DumpersList from "./pages/dumpers-list.component";

import Remorque from "./pages/remorque.component";
import RemorquesList from "./pages/remorques-list.component";

import AutresList from "./pages/autres-list.component";
import Autre from "./pages/autre.component";

import SearchList from "./pages/search-list.component";



import Header from "./components/header";
import Navbar from "./components/Navbar";
import Home from "./pages/Home"
import Recrutement from "./pages/recrutement";
import ChenillardsList from "./pages/chenillards-list.component";
import Chenillard from "./pages/chenillard.component";

import VentesList from "./pages/ventes-list.component";
import Vente from "./pages/vente.component";
import Contact from "./pages/contact";
import Condition from './pages/condition';

import EnginsList from "./pages/engins-list.component";
import Engin from "./pages/engin.component";


const Footer = () => (
    <footer >
      <div className="footer">
          <tr>
              <td id="gauche">
          <li> <a href="mailto:location@attirail.fr">location@attirail.fr</a></li>
          <li> 01.69.40.40.29</li>
          </td>
              <td id="droite">
                  <li>
                  <Link to={"/conditions"}>

                  <p id="lien">  Voir les conditions générales de location</p>
                  </Link></li>
                  <li> 27 Rue de la Longueraie, 91270 Vigneux-sur-Seine </li>
          </td>
              </tr>


      </div>
    </footer>
);


class App extends Component {






  render() {

    return (

      <Router>
        <div className="content">
        <Header/>
        <Navbar/>


          <Switch>

            <Route exact path= "/pelles" component={PellesList} />
            <Route path="/pelles/:id" component={Pelle} />

            <Route exact path= "/location" component={CategoriesList} />

            <Route exact path= "/nacelles" component={NacellesList} />
            <Route path="/nacelles/:id" component={Nacelle} />

            <Route exact path= "/dumpers" component={DumpersList} />
            <Route path="/dumpers/:id" component={Dumper} />

            <Route exact path= "/remorques" component={RemorquesList} />
            <Route path="/remorques/:id" component={Remorque} />


              <Route exact path= "/chenillards" component={ChenillardsList} />
            <Route path="/chenillards/:id" component={Chenillard} />

            <Route exact path= "/equipements" component={AutresList} />
            <Route path="/equipements/:id" component={Autre} />

              <Route exact path="/ventes" component={VentesList}/>
              <Route path="/ventes/:id" component={Vente}/>

              <Route exact path="/Engin-tps" component={EnginsList}/>
              <Route path="/engins/:id" component={Engin}/>

            <Route path="/searchbar/" component={SearchList} />

              <Route path="/recrutement/" component={Recrutement}/>
              <Route path="/contact/" component={Contact}/>

              <Route path="/conditions" component={Condition}/>



              <Route exact path="/" component={Home}/>


          </Switch>
        </div>




        <Footer/>




      </Router>
    );
  }
}

export default (App);

