import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveCategories,
  findCategoriesByTitle,

} from "../Categorie/actions/categories";
import { Link } from "react-router-dom";

import Searchbar from "../components/searchbar";
import {ScrollArrow} from "../scroll/scroll";


class CategoriesList extends Component {
  constructor(props) {

    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.props=props;



    this.state = {
      currentCategorie: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveCategories();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentCategorie: null,
      currentIndex: -1,
    });
  }

  setActiveCategorie(categorie, index) {
    this.setState({
      currentCategorie: categorie,
      currentIndex: index,
    });
  }


  findByNom() {
    this.refreshData();

    this.props.findCategoriesByTitle(this.state.searchNom);
  }

  render() {
    const {searchNom, currentIndex} = this.state;
    const {categories} = this.props;
    console.log({categories});



    return (
        <div className="pagecategorie">
          <div className="hautpage">
            <h2 className="maj"> TOUS NOS MATÉRIELS PROFESSIONNELS À LOUER
            </h2>
            <h4>Élévation, transport, équipements… Découvrez tous nos univers de matériels disponibles à
              la location pour les professionnels du BTP, bâtiment et travaux publics. Louez le matériel qu'il vous faut, quand il faut, et où il faut !</h4>

          </div>
          <div className="searchbar">
            <Searchbar/>
          </div>

          <div className="noscategories">
            <div className="bloc">
            <tr>
              <td id="text">
                <p> NOS CATEGORIES </p>
              </td>
              <td id="att">
                <h3> Tous les engins sont à louer avec un opérateur</h3>
              </td>

            </tr>
            </div>
          </div>

          <div>

            <div >
              <div className="list-group" id="list-group">
                <div>

                  <br/>
                  <br/>
                  <tr>


                    {categories &&
                        categories.map((categorie, index) => (

                            <td>
                              <Link to={"/" + categorie.Nom + "s" + "/"}>

                                <div className="container" id="container" style={{backgroundImage: `url(${categorie.Img1})`}}>

                                  <div className="nomcat">

                                    <h2
                                        className={
                                            "list-group-item " +
                                            (index === currentIndex ? "active" : "")
                                        }

                                        onClick={() => this.setActiveCategorie(categorie)}
                                        key={categorie.id}
                                        style={{textAlign: 'center'}}
                                    >
                                      {categorie.Nom}
                                    </h2>


                                  </div>
                                </div>

                              </Link>
                            </td>
                        ))}

                  </tr>
                  <div className="fleches" >
                    <ScrollArrow/>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {retrieveCategories,findCategoriesByTitle})(CategoriesList)
