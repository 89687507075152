import {
  CREATE_VENTE,
  RETRIEVE_VENTES,
} from "./types";

import VentesDataService from "../services/vente.service";

export const createVentes = (Nom, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await VentesDataService.create({ Nom, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_VENTE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await VentesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveVentes = () => async (dispatch) => {

  try {
    const res = await VentesDataService.getAll();

    dispatch({
      type: RETRIEVE_VENTES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};




export const findVenteByTitle = (nom) => async (dispatch) => {
  try {
    const res = await VentesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_VENTES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};