import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePelle, deletePelle } from "../Pelle/actions/pelles";
import PellesDataService from "../Pelle/services/pelle.service";

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";
import { PDFViewer } from 'react-view-pdf';



class Pelle extends Component {

    constructor(props) {
        super(props);

        this.getPelle = this.getPelle.bind(this);
        this.goBack = this.goBack.bind(this);


        this.state = {
            currentPelle: {
                id: null,
                Nom: "",
                Poids: "",
                Categorie: "",
                Profondeur_de_fouille: "",
                Portee_laterale: "",
                Hauteur_de_travail: "",
                Longeur: "",
                Largeur: "",
                Hauteur: "",
                published: false,
            },
            message: "",
            selectedFiles1: undefined,
            selectedFiles2: undefined,
            progress: 0,
            fileName: "",
            progress2: 0,
            fileName2: "",
            progress3: 0,
            fileName3: "",
            progress4: 0,
            fileName4: "",
            fileInfos: [],

        };
    }

    componentDidMount() {
        this.getPelle(this.props.match.params.id);

    }

    goBack() {
        this.props.history.goBack();

    }

    getPelle(id) {
        PellesDataService.get(id)
            .then((response) => {
                this.setState({
                    currentPelle: response.data,

                });
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }


    render() {

        const {
            currentPelle,
        } = this.state;
        const fichetechnique = currentPelle.Fiche_technique;

        return (
            <div>
                    <div className="pageproduit">

                        <tr>
                            <td className="goback">
                                <div className="textgoback">
                                    <a onClick={this.goBack}><p> Revenir à la page précédente</p></a>
                                </div>
                                <h1> {currentPelle.Nom} </h1>
                            </td>

                        </tr>
                        <tr><h3> Agrée SNCF </h3></tr>

                        <tr className="infoproduit">
                            <td>
                                <div className="Carouselproduit">
                                    <AliceCarousel
                                        autoPlay={true}
                                        autoPlayInterval={4000}
                                        infinite={true}>
                                        <img src={currentPelle.Img1} className="sliderimg" alt="img1"/>
                                        <img src={currentPelle.Img2} className="sliderimg" alt="img2"/>
                                        <img src={currentPelle.Img3} className="sliderimg" alt="img3"/>
                                    </AliceCarousel></div>
                            </td>

                            <td className="carac">
                                <h2>
                                    Caractéristiques
                                </h2>
                                <div className="caracteristiques">
                                    <li id="carac">

                                        Poids : {currentPelle.Poids}

                                    </li>
                                    <li id="carac">
                                        Profondeur de fouille : {currentPelle.Profondeur_de_fouille}

                                    </li>

                                    <li id="carac">
                                        Portée latérale : {currentPelle.Portee_laterale}
                                    </li>

                                    <li id="carac">
                                        Hautaur de travail :
                                        {currentPelle.Hauteur_de_travail}
                                    </li>

                                    <li id="carac">
                                        Longueur : {currentPelle.Longueur}

                                    </li>
                                    <li id="carac">

                                        Largeur : {currentPelle.Largeur}
                                    </li>

                                    <li id="carac">

                                        Hauteur : {currentPelle.Hauteur}

                                    </li>
                                    <div className="fichetechnique">
                                    {fichetechnique !== 'NULL' && (
                                        <li className="fichetechnique"><a href={currentPelle.Fiche_technique}>
                                            <p>Télécharger la fiche technique</p>

                                        </a></li>
                                    )}
                                    </div>

                                </div>
                            </td>


                            <td id="tddevis">

                                <div className="devis">
                                    <Link to={"/contact"}>
                                        <button> Demander un devis</button>
                                    </Link>
                                </div>
                            </td>


                        </tr>
                        {fichetechnique !== 'NULL' && (
                            <div className="pdfviewer">

                                <h4>Fiche technique</h4>

                                <PDFViewer url={currentPelle.Fiche_technique}/>
                            </div>
                        )}


                    </div>

            </div>
        );
    }
}

export default connect(null, { updatePelle, deletePelle })(Pelle);