import http from "../../http-common";

class NacellesDataService {

  upload(files, onUploadProgress) {
    let formData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('file', files[i]);
    }

    return http.post("/fichiernacelles/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/fichiernacelles/getListFiles");
  }

  getAll() {
    return http.get("/nacelles");
  }

  get(id) {
    return http.get(`/nacelles/${id}`);
  }

  create(data) {
    return http.post("/nacelles", data);

  }

  update(id, data) {
    return http.put(`/nacelles/${id}`, data);

  }

  delete(id) {
    return http.delete(`/nacelles/${id}`);
  }

  deleteAll() {
    return http.delete(`/nacelles`);
  }

  findByTitle(Nom) {
    return http.get(`/nacelles?nom=${Nom}`);
  }
}

export default new NacellesDataService();