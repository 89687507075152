import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveRemorques,
  findRemorquesByTitle,

} from "../Remorque/actions/remorques";
import { Link } from "react-router-dom";
import Searchbar from "../components/searchbar";
import back from "../photo/fond_image.png";
import {FaArrowCircleLeft} from "react-icons/fa";

class RemorquesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveRemorque = this.setActiveRemorque.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllRemorques = this.removeAllRemorques.bind(this);
    this.goBack=this.goBack.bind(this);

    this.state = {
      currentRemorque: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveRemorques();


  }

  goBack() {
    this.props.history.goBack();

  }



  refreshData() {
    this.setState({
      currentRemorque: null,
      currentIndex: -1,
    });
  }

  setActiveRemorque(remorque, index) {
    this.setState({
      currentRemorque: remorque,
      currentIndex: index,
    });
  }



  findByNom() {
    this.refreshData();

    this.props.findRemorquesByTitle(this.state.searchNom);
  }

  render() {
    const { currentIndex } = this.state;
    const { remorques } = this.props;


    return (
        <div className="list-remorque" >
          <div className="fond" style={{backgroundImage: `url(${back})`}}>

          <div className="hautpage">
            <div className="textgoback">
              <tr onClick={this.goBack}>  <th><FaArrowCircleLeft className="scrollback" style={{height: 40, 'flex' : 'none'}}/></th>
                <th><p> Revenir à la page précédente</p></th></tr>
            </div>
            <h2 className="maj"> TOUS NOS REMORQUES PROFESSIONNELS À LOUER
            </h2>
            <h4>Les remorques sont compatibles uniquement avec certaines pelles, veuillez nous contacter pour en
            savoir plus</h4>
          </div>
          <div className="searchbar">
            <Searchbar/>
          </div>
          </div>
          <div className="col-md-6">
            <div className="nomcategorie">
              <div className="bloc">
                <tr>
                  <td id="text">
                    <p> NOS REMORQUES </p>
                  </td>
                  <td id="att">
                    <h3> Tous les engins sont à louer avec ou sans opérateur</h3>
                  </td>

                </tr>
              </div>
            </div>


            <div className="listerecherche">
              {remorques &&
              remorques.map((remorque, index) => (

                  <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }

                      onClick={() => this.setActiveRemorque(remorque)}
                      key={remorque.id}

                  >
                    <Link
                        to={"/" + remorque.Categorie + "/" + remorque.id}
                        className="badge badge-warning"
                    >
                      <td>
                        <img className="img" src={remorque.Img1} alt={remorque.Img1}/>
                      </td>
                      <td>
                        <p> {remorque.Nom}</p>
                      </td>
                      <td>
                      </td>
                      <td>
                        <div className="buttonvoir">
                          <button type="button">
                            <p>
                              > Voir le matériel
                            </p>
                          </button>
                        </div>

                      </td>



                    </Link>
                  </li>


              ))}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    remorques: state.remorques,
  };
};

export default connect(mapStateToProps, {
  retrieveRemorques,
  findRemorquesByTitle,
})(RemorquesList);
