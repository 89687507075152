import http from ".././http-common";

class ContactService {

    upload(file, onUploadProgress) {
        let formData = new FormData();

        formData.append('file' , file);

        return http.post("/fichiercontacts/upload/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }
    getFiles() {
        return http.get("/fichiercontacts/getListFiles");
    }

}
export default new ContactService();

