import http from "../../http-common";

class VentesDataService {


  getFiles() {
    return http.get("/fichierventes/getListFiles");
  }

  getAll() {
    return http.get("/ventes");
  }

  get(id) {
    return http.get(`/ventes/${id}`);
  }




  findByTitle(Nom) {
    return http.get(`/ventes?nom=${Nom}`);
  }
}

export default new VentesDataService();