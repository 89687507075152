import {

  RETRIEVE_NACELLES,

} from "../actions/types";

const initialState = [];

function nacelleReducer(nacelles = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_NACELLES:
      return payload;

    default:
      return nacelles;
  }
}

export default nacelleReducer;
