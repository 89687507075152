import http from "../../http-common";

class CategoriesDataService {



  getAll() {
    return http.get("/categories");
  }

  get(id) {
    return http.get(`/categories/${id}`);
  }

  findByTitle(Nom) {
    return http.get(`/categories?nom=${Nom}`);
  }
}

export default new CategoriesDataService();