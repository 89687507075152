import React, { Component } from "react";
import { connect } from "react-redux";
import { updateDumper, deleteDumper } from "../Dumper/actions/dumpers";
import DumpersDataService from "../Dumper/services/dumper.service";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";
import { PDFViewer } from 'react-view-pdf';


class Dumper extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);

    this.getDumper = this.getDumper.bind(this);

    this.state = {
      currentDumper: {
        id: null,
        Nom: "",
        Charge_max:"",
        Volume_max:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: "",
        published:false,
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getDumper(this.props.match.params.id);
  }

  goBack() {
    this.props.history.goBack();

  }

  getDumper(id) {
    DumpersDataService.get(id)
      .then((response) => {
        this.setState({
          currentDumper: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  render() {

    const {
      currentDumper,
    } = this.state;
    const fichetechnique=currentDumper.Fiche_technique;


    return (
        <div>
          <div className="pageproduit">

            <tr>
              <td className="goback">
                <div className="textgoback">
                  <a onClick={this.goBack}><p> Revenir à la page précédente</p></a>
                </div>
                <h1> {currentDumper.Nom} </h1>
              </td>

            </tr>
            <tr> <h3> Agrée SNCF </h3></tr>

            <tr className="infoproduit">
              <td>
                <div className="Carouselproduit">
                  <AliceCarousel
                      autoPlay={true}
                      autoPlayInterval={4000}
                      infinite={true} >
                    <img src={currentDumper.Img1} className="sliderimg" alt="img1"/>
                    <img src={currentDumper.Img2} className="sliderimg" alt="img2"/>
                    <img src={currentDumper.Img3} className="sliderimg" alt="img3"/>
                  </AliceCarousel></div>
              </td>

              <td className="carac">
                <h2>
                  Caractéristiques
                </h2>
                <div className="caracteristiques">
                  <li id="carac">
                    Charge maximum : {currentDumper.Charge_max}

                  </li>
                  <li id="carac">
                    Volume maximum : {currentDumper.Volume_max}

                  </li>


                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="fichetechnique">
                  {fichetechnique!=='NULL' && (
                      <a href={currentDumper.Fiche_technique}>
                        <p className="fichetechnique" >

                          Télécharger la fiche technique

                        </p>
                      </a>)}
                    </div>
                </div>
              </td>

              <td id="tddevis">
                <div className="devis">
                  <Link to={"/contact"}>
                    <button> Demander un devis </button>
                  </Link></div>
              </td>


            </tr>
            {fichetechnique!=='NULL' && (
                <div className="pdfviewer">

                  <h4>Fiche technique</h4>

                  <PDFViewer url={currentDumper.Fiche_technique} />
                </div>
            ) }





          </div>

        </div>
    );
  }
}

export default connect(null, { updateDumper, deleteDumper })(Dumper);
